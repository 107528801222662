$overwrite-colors: 'primary', 'success', 'warning', 'info';

.btn-lg {
  .btn-check + & {
    border-radius: $border-radius-lg;
  }
}

.btn-close {
  padding: 0px;
  width: 32px;
  height: 32px;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    .btn-check + & {
      color: $body-color;
      box-shadow: inset 0 0 0 map-get($border-widths, 2) $white;
      background-color: $gray-200;
      border: $border-width solid $gray-300;

      &:hover {
        color: $body-color;
      }
    }

    .btn-check:focus + & {
      box-shadow: 0 0 0 $btn-focus-width rgba(mix($value, $value, 15%), 0.5),
        inset 0 0 0 map-get($border-widths, 2) $white;
    }

    .btn-check:checked + &,
    .btn-check:active + & {
      &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix($value, $value, 15%), 0.5),
          inset 0 0 0 map-get($border-widths, 2) $white;
      }

      &:hover {
        color: $white;
      }
    }
  }
}

@each $color, $value in $overwrite-colors {
  .btn-#{$color} {
    color: $white;

    &:hover {
      color: $white;
    }

    .btn-check:focus + &,
    &:focus {
      color: $white;
    }

    .btn-check:checked + &,
    .btn-check:active + & {
      color: $white;
    }

    &:disabled,
    &.disabled {
      color: $white;
    }
  }

  .btn-outline-#{$color} {
    &:hover {
      color: $white;
    }
  }
}

.btn-light {
  color: $primary;

  &:hover {
    color: $primary;
  }

  .btn-check:focus + &,
  &:focus {
    color: $primary;
  }

  &:disabled,
  &.disabled {
    color: $primary;
  }
}

.btn-outline-light {
  color: $primary;

  &:hover {
    color: $primary;
  }

  &:disabled,
  &.disabled {
    color: $primary;
  }
}

.btn-link {
  font-weight: $btn-font-weight;
}
