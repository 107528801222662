.default-modal-backdrop {
  background-color: rgba($black, 0.5);
  backdrop-filter: blur(2px);
}

.default-modal-body {
  background-color: $body-bg;
  border-radius: $border-radius-modal;
  overflow-y: auto;
  height: 100vh;
  width: 100vw;
}

.monitored-user-details-modal-body {
  width: 100%;
  max-width: 1140px !important;
  overflow-y: hidden;
  left: 50%;
  top: 5%;
  position: fixed;
}

.remove-modal-dialog {
  max-width: 500px;
  position: relative;
  pointer-events: none;
}

.remove-user-modal-body {
  background-color: white;
  margin: 0rem;
  width: fit-content;
}

.modal-close {
  padding: 0px;
  width: 32px;
  height: 32px;
}

.card-body-headline {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.card-body-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.default-modal-headline {
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 714px) {
  .default-modal-body {
    height: fit-content !important;
    max-width: 60vw;
  }

  .default-modal-headline {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.1px;
  }
}

@media (max-width: 576px) {
  .modal-body-headline {
    font-size: 14px;
    line-height: 18px;
  }

  .modal-body-text {
    font-size: 16px;
    line-height: 21px;
  }
}
