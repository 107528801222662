// Position
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: $spacer;
  left: $spacer;
}
.toast-top-right {
  top: $spacer;
  right: $spacer;
}
.toast-bottom-right {
  right: $spacer;
  bottom: $spacer * .5;
}
.toast-bottom-left {
  bottom: $spacer * .5;
  left: $spacer;
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: calc(100% - (#{$spacer} * 2));
  margin-left: auto;
  margin-right: auto;
}

// Toast
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;

  .ngx-toastr {
    @include border-radius($border-radius-lg);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 0;
    margin-bottom: $spacer * .5;
    padding: 1rem;
    width: 350px;
    background-color: $white;
    pointer-events: auto;

    .toast-icon {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      margin-right: $spacer;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .toast-icon {
      margin-right: $spacer;
    }
    .toast-title {
      font-weight: bold;
    }
    .toast-message {
      font-weight: bold;
      font-size: $small-font-size;
      word-wrap: break-word;
      margin-right: 1rem;
    }
    .toast-message a:hover {
      text-decoration: underline;
    }
    .toast-close-button {
      flex-shrink: 0;
      text-indent: -99999px;
      overflow: hidden;
      height: 14px;
      width: 14px;
      margin-left: auto;
      background: url('../assets/images/close-icon.svg') center right no-repeat;
      background-size: 14px;
      border: 0;
      padding: 0;
    }
    .toast-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      background-color: #000000;
      opacity: 0.3;
    }
    .toast-close-button:hover,
    .toast-close-button:focus {
      text-decoration: none;
      cursor: pointer;
      opacity: 0.75;
    }
  }
  .ngx-toastr:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.toast-success {
  color: $success;

  .toast-icon {
    background-image: url('../assets/images/success-icon.svg');
  }
}
.toast-error {
  color: $danger;

  .toast-icon {
    background-image: url('../assets/images/danger-icon.svg');
  }
}
.toast-info {
  color: $primary;

  .toast-icon {
    background-image: url('../assets/images/info-icon.svg');
  }
}
.toast-warning {
  color: $warning;

  .toast-icon {
    background-image: url('../assets/images/warning-icon.svg');
  }
}

// Responsive Design
@media all and (max-width: map-get($container-max-widths, 'md')) {
  .toast-bottom-right {
    bottom: $spacer * .5;
    right: 0;
    width: 100%;
  }

  .toast-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ngx-toastr {
      width: calc(100% - (#{$spacer} * 2));
    }
  }

}
