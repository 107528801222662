.card {
  box-shadow: $card-box-shadow;

  .card-header-tabs {
    margin-right: 0;
    margin-bottom: -$card-cap-padding-y * 0.5;
    margin-left: 0;
  }

  h2 {
    font-size: $font-size-base * 1.5;
  }

  .title {
    font-weight: bold;
  }
}
