.nav-tabs {
  position: relative;
  border-bottom: transparent;
  display: flex;
  justify-content: space-between;

  &:before {
    content: '';
    background: $nav-tabs-border-color;
    position: absolute;
    bottom: -$nav-tabs-border-width;
    left: 0;
    width: 100%;
    height: $nav-tabs-border-width;
    border-radius: $border-radius-pill;
  }

  &.dark {
    &:before {
      background: rgba($nav-tab-underline-color, 0.2);
    }

    .nav-link {
      color: $white;

      &:hover,
      &:focus,
      &.active {
        color: $white;
      }
    }
  }

  .nav-link {
    position: relative;
    margin-bottom: -$nav-tabs-border-width;
    background: none;
    border: $nav-tabs-border-width solid transparent;
    white-space: nowrap;
    font-weight: $btn-font-weight;
    cursor: pointer;
    color: $white;

    &:after {
      content: '';
      background: transparent;
      position: absolute;
      bottom: -$nav-tabs-border-width;
      left: 20%;
      width: 60%;
      height: $nav-tabs-border-width;
      border-radius: $border-radius-pill;
      @include transition($nav-link-transition);
    }

    &:hover,
    &:focus {
      border-color: transparent;
      color: $nav-tabs-link-active-color;

      &:after {
        background: $link-color;
      }
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;
    background-color: transparent;
    border-color: transparent;

    &:after {
      background: $link-color;
    }
  }
}

.nav-fill,
.nav-justified {
  .nav-item {
    display: flex;
    justify-content: center;

    .nav-link {
      width: min-content;
    }
  }
}

@media (max-width: 714px) {
  .nav-tabs {
    font-size: 12px;
  }
}
