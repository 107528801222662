.navbar {
  height: $navbar-height;
}

.navbar-toggler {
  border: none;
}

.navbar-link {
  color: $white;

  &:hover,
  &:focus,
  &.active {
    color: $white;
  }

  display: block;
  padding: 0.5rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.navbar-tabs {
  position: relative;
  border-bottom: transparent;
  display: flex;
  justify-content: space-between;

  &:before {
    content: '';
    background: $nav-tab-underline-color;
    position: absolute;
    bottom: -$nav-tabs-border-width;
    left: 0;
    width: 100%;
    height: $nav-tabs-border-width;
    border-radius: $border-radius-pill;
  }

  &.dark {
    &:before {
      background: rgba($nav-tab-underline-color, 0.2);
    }
  }

  .navbar-item.show {
    color: $white;
    background-color: transparent;
    border-color: transparent;

    &:after {
      background: $link-color;
    }
  }
}

.navbar-fill,
.navbar-justified {
  .navbar-item {
    display: flex;
    justify-content: center;

    .navbar-link {
      width: min-content;
    }
  }
}
