// Variables

// Color system

// gray-color-variables
$white:     #FFF !default;
$gray-100:  #F1F3F6 !default;
$gray-200:  #EDEEF1 !default;
$gray-300:  #DBE0EB !default;
$gray-400:  #C3CBD1 !default;
$gray-500:  #A2A9BB !default;
$gray-600:  #737988 !default;
$gray-700:  #474C57 !default;
$gray-800:  #2B303A !default;
$gray-900:  #191B24 !default;
$black:     #000000 !default;

// color-variables
$blue:                #30BCED !default;
$dark-blue:           #1e2229 !default;
$red:                 #E8346A !default;
$orange:              #FF7A00 !default;
$green:               #34DC77 !default;
$teal:                #6EEB83 !default;
$cyan:                #0dcaf0 !default;
$yellow:              #E9C33F !default;

// theme-color-variables

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $white !default;
$dark:          $gray-900 !default;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;


$min-contrast-ratio: 3 !default;

// Options
$enable-validation-icons:     false !default;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .5,
  2: $spacer,
  3: $spacer * 1.25,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 4,
) !default;

// Body
$body-bg:                   opaque($black, rgba(43, 48, 58, 0.7)) !default;
$body-color:                $gray-800 !default;

// Links
$link-color:                              $primary !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;

// Grid & Containers

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 632px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

$grid-gutter-width:           1rem !default;
$container-padding-x:         $grid-gutter-width !default;

// Components

$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .75rem !default;
$border-radius-pill:          50rem !default;
$border-radius-modal:         1rem !default;

$box-shadow:                  0 .25rem .875rem rgba($black, .45) !default;

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

$transition-collapse:         height .10s ease !default;

// Typography
$font-family-sans-serif:      'Nunito', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-weight-bold:            700 !default;
$font-size-base:              1rem !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base !default;
$h5-font-size:                $font-size-base !default;
$h6-font-size:                $font-size-base * 0.75 !default;

$headings-font-weight:        700 !default;

$small-font-size:             .875em !default;

// Buttons + Forms

// scss-docs-start input-btn-variables
$input-btn-padding-y-lg:      .6875rem !default;
$input-btn-padding-x-lg:      .75rem !default;
$input-btn-font-size-lg:      $font-size-base !default;


$input-btn-border-width:      map-get($border-widths, 2) !default;

//$input-font-size-lg:                    $font-size-base !default;
$input-bg:                              $gray-200 !default;
$input-border-color:                    $gray-200 !default;

// Buttons

// btn-variables
$btn-padding-x-lg:            1.25rem !default;
$btn-disabled-opacity:        .5 !default;

$btn-font-weight:             $font-weight-bold !default;

$btn-border-radius:           $border-radius-pill !default;
$btn-border-radius-sm:        $border-radius-pill !default;
$btn-border-radius-lg:        $border-radius-pill !default;

// Forms

// form-label-variables
$form-label-font-weight:            700 !default;

// form-input-variables

// form-check-variables
//$form-check-margin-bottom:                1rem !default;
$form-check-input-width:                  1.5em !default;
$form-check-label-cursor:                 pointer !default;
$form-check-input-border:                 none !default;

// form-select-variables
$form-select-padding-x:             $input-btn-padding-x-lg !default;
$form-select-indicator-padding:     $form-select-padding-x * 4 !default;
$form-select-font-weight:           700 !default;
$form-select-color:                 $primary !default;
$form-select-bg-position:           right 1rem center !default;
$form-select-bg-size:               16px 16px !default;

// form-range-variables
$form-range-track-bg:               $gray-100 !default;

$form-range-thumb-width:            2rem !default;
$form-range-thumb-bg:               $component-active-bg !default;
$form-range-thumb-active-bg:        $component-active-bg !default;

// Navs
$nav-link-color:                    $body-color !default;
$nav-link-hover-color:              $link-color !default;

$nav-tabs-border-width:             map-get($border-widths, 2) !default;
$nav-tabs-link-active-color:        $link-color !default;

$nav-tab-underline-color: #716b76 !default;

// Navbar
$navbar-height:                     56px !default;

$navbar-toggler-border-radius:      $border-radius !default;
$navbar-toggler-focus-width:        0 !default;

// Cards
$card-spacer-y:                     map-get($spacers, 2) !default;
$card-spacer-x:                     map-get($spacers, 2) !default;
$card-border-color:                 $white !default;
$card-border-radius:                $border-radius-lg !default;
$card-box-shadow:                   0 .25rem 1.5rem rgba($black, .32) !default;
$card-cap-padding-y:                $card-spacer-y !default;
$card-cap-bg:                       transparent !default;

// Modals

$modal-header-border-width:         0 !default;
$modal-footer-border-width:         0 !default;

// Alerts
$alert-padding-y:                   map-get($spacers, 3) !default;
$alert-padding-x:                   map-get($spacers, 3) !default;
$alert-border-radius:               $border-radius !default;
$alert-border-width:                0 !default;
$alert-bg-scale:                    -84% !default;
$alert-color-scale:                 0% !default;

// Progress bars
$progress-height:                   0.25rem !default;

// Carousel

// scss-docs-start carousel-variables
$carousel-indicator-width:           8px !default;
$carousel-indicator-height:          8px !default;
$carousel-indicator-hit-area-height: 24px !default;
$carousel-indicator-spacer:          map-get($spacers, 1) !default;
$carousel-indicator-opacity:         1 !default;
$carousel-indicator-active-bg:       $body-bg !default;
$carousel-indicator-active-opacity:  1 !default;