%survey-dot {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: $border-width solid $white;
}

.default-date-picker {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 4px;
  width: 313px;
  height: 56px;
  color: white;
  text-align: center;
  background-image: url('../assets/images/calendar.svg');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

.bs-datepicker-container {
  padding: 0px;
}

.bs-datepicker {
  border: 3px solid var(--bs-blue);
  border-radius: $border-radius-lg;
  box-shadow: $card-box-shadow;

  bs-datepicker-inline & {
    box-shadow: none;
  }
}

.bs-datepicker-head {
  background-color: transparent;
  background: var(--bs-blue);
  text-align: center;

  button {
    color: black;

    &:hover,
    &:active {
      background-color: transparent;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
      background: transparent;
    }

    &.previous,
    &.next {
      color: $gray-300;
    }

    &.previous span {
      text-indent: -99999em;
      overflow: hidden;
      background-image: url('../assets/images/arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
      transform: rotate(180deg);
    }

    &.next span {
      text-indent: -99999em;
      overflow: hidden;
      background-image: url('../assets/images/arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }

    &.current {
      padding: 0;
      font-weight: $font-weight-bold;
    }
  }
}

.bs-datepicker-body {
  border: none;

  table {
    th {
      color: $gray-600;
    }

    td {
      color: $body-color;
      font-weight: $font-weight-bold;

      .survey-success {
        color: $white;
        background: $success !important;
      }

      .survey-danger {
        color: $white;
        background: $danger !important;
      }

      .survey-warning {
        color: $white;
        background: $warning !important;
      }

      span.selected,
      &.selected span {
        position: relative;
        background: $primary !important;

        &.survey-success:after {
          @extend %survey-dot;
          background: $success;
        }

        &.survey-danger:after {
          @extend %survey-dot;
          background: $danger;
        }

        &.survey-warning:after {
          @extend %survey-dot;
          background: $warning;
        }
      }
    }
  }
}

.theme-green {
  .bs-datepicker-head {
    background: var(--bs-blue);
    text-align: center;
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected,
        &.selected span,
        span[class*='select-']:after,
        &[class*='select-'] span:after {
          background-color: $primary;
        }

        &.week span {
          color: $primary;
        }

        &.active-week span:hover {
          cursor: pointer;
          background-color: tint-color($primary, 80%);
          color: $primary;
          opacity: 0.5;
          transition: 0s;
        }
      }
    }
  }
}
