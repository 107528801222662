// Functions
@import "bootstrap/scss/functions";

// Custom
@import "./variables";

// Configuration
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/close";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/dropdown";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// 3rd party
@import "@angular/cdk/overlay-prebuilt.css";
@import "ngx-bootstrap/datepicker/bs-datepicker";

// Local overrides or additional partials
@import "./utilities";
@import "./reboot";
@import "./containers";
@import "./forms";
@import "./buttons";
@import "./nav";
@import "./navbar";
@import "./card";
@import "./alert";
@import "./carousel";
@import "./tables";
@import "./modals";
@import "./calendar";
@import "./toasts";
@import "./slider";
@import "./tab";

.app-container {
  height: calc(100vh - $navbar-height);
}

.cursor-pointer {
  cursor: pointer;
}
