.carousel-indicators {
  bottom: -55px;

  [data-bs-target] {
    border: map-get($border-widths, 2) solid $white;
    border-radius: $border-radius-pill;
  }

  .active {
    background: $primary;
    border-color: $primary;
  }
}
