.tab {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.tab-underline {
  color: white;
  position: relative;
  bottom: 16px;
}

@media (max-width: 576px) {
  .tab {
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
  }
}
